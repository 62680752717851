/* globals gtag */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Link } from 'gatsby';
import { useWindowWidth } from '@react-hook/window-size';

import './styles.scss';
import { Footer } from '../Footer/Footer';

const Layout = inject('store')(observer(({ store, children }) => {
    const classes = ['root', store.menuState ? 'menu-open' : null, store.imageView ? 'image-preview' : null];
    let width = null; // eslint-disable-line no-unused-vars
    if (typeof window !== `undefined`) {
        [width] = useWindowWidth();
    }

    useEffect(() => {
        store.changeScreenType(getComputedStyle(document.documentElement).getPropertyValue('--screen-resolution'));
    });

    const navigateToPaintings = () => {
        gtag('config', 'UA-162933668-1', { page_path: `/` });
        store.toggleMenu();
    };

    const navigateToGraphics = () => {
        gtag('config', 'UA-162933668-1', { page_path: `/graphics` });
        store.toggleMenu();
    };

    const navigateToAbout = () => {
        gtag('config', 'UA-162933668-1', { page_path: `/about` });
        store.toggleMenu();
    };

    return (
        <div className={classes.filter(x => !!x).join(' ')}>
            <nav>
                <div className="logo">
                    <div className="img"></div>
                </div>
                <menu>
                    <button className="menu-button" onClick={store.toggleMenu}>
                        <span className="menu-icon"></span> Menu
                    </button>
                </menu>
                <div className="spacer" />
                <ul className="menu-items">
                    <li><Link to="/" onClick={navigateToPaintings}>Paintings</Link></li>
                    <li><Link to="/graphics" onClick={navigateToGraphics}>Graphics</Link></li>
                    <li><Link to="/about" onClick={navigateToAbout}>About</Link></li>
                </ul>
            </nav>
            {children}
            <aside>
                <div className="top">
                    <span>kbessm@gmail.com <i className="icon-mail-alt" /></span>
                </div>
                <div className="bottom">
                    <span>&copy; 2020 Copyright reserved</span>
                </div>
            </aside>
            <Footer />
        </div>
    );
}));

Layout.propTypes = {
    children: PropTypes.node
};

export { Layout };