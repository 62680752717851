import React, { useRef } from 'react';
import { observer, inject } from 'mobx-react';

import { Legend } from '../Legend/Legend';
import './styles.scss';

const showInfo = (ref) => {
    ref.current.classList.add('show');
    setTimeout(() => {
        if (ref.current) {
            ref.current.classList.remove('show');
        }
    }, 3000);
};

export const Footer = inject('store')(observer(({store}) => {
    if (store.currentPage === 'about') {
        return null;
    }

    const legendRef = useRef(null);

    const infoContent = store.selectedImage
    ? (
        <div className="image-name">
            <button onClick={() => store.hideImageView(store.selectedImage)}>
                <span className="close-preview" />
                {store.selectedImage?.title} <span className="year">({store.selectedImage?.year})</span>
            </button>
        </div>
    ) : (
        <>
            <hr className="line short white" /> scroll & swipe
        </>
    );

    const legend = store.selectedImage
    ? (
        <div className="legend" ref={legendRef}>
            <span>{store.selectedImage?.materials}</span>
            <i className="icon-info-circled" onClick={() => showInfo(legendRef)} aria-hidden="true" />
        </div>
    ) : (
        <Legend />
    );

    return (
        <footer>
            <div className="info">
                {infoContent}
            </div>
            { legend }
        </footer>
    );
}));
