import React from 'react';
import { inject, observer } from 'mobx-react';
import './styles.scss';

export const Legend = inject('store')(observer(({ store }) => {
    const currentPageList = store[store.currentPage];
    if (!currentPageList) {
        return null;
    }

    const numImages = store.screenType === 'mobile' ? currentPageList.list.length : Math.ceil(currentPageList.list.length / 3);
    const currentPageNumber = store.screenType === 'mobile'
        ? currentPageList.leftMost + 1
        : Math.floor(currentPageList.leftMost / 3) + 1;
    const position = store.screenType === 'mobile'
        ? Math.floor(100 / numImages * currentPageList.leftMost)
        : Math.floor(100 / numImages * currentPageList.leftMost / 3)

    return (
        <div className="legend">
            <div className="scrollArea">
                <span className="indicator" style={{width: `${Math.floor(100 / numImages)}%`, left: `${position}%`}} />
            </div>
            { currentPageNumber > 9 ? currentPageNumber : `0${currentPageNumber}` } / {numImages > 9 ? numImages : `0${numImages}`}
        </div>
    );
}));
